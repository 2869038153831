'use client';
import { OfferService } from '@/service/Offer';
import PaginatedOffersList from '@/components/PaginatedOffersList';
import { AndroidService } from '@/service/Android';
import ActiveOfferCard from '../ActiveOfferCard';
import { OFFER_404 } from '@/constants/logs';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';
import { makeMap } from '@/utils';
import { OfferV6 } from '@/model/offer';
import { useMemo, useState } from 'react';
import useAuthContext from '@/context/authContext';

export default function AllOffers() {
  const lang = StorageService.getLanguage();
  const {
    activeSort: sort,
    setSort,
    activeTags: tags,
    setTags,
  } = useAuthContext();

  const tagMap = useMemo(() => {
    return makeMap(OfferV6.GET_TAGS(lang), d => d.id);
  }, [lang]);

  const removeDuplicateOffers = data => {
    const uniqueArray = Array.from(
      new Set(data.map(obj => obj.androidPackageName))
    ).map(id => {
      return data.find(obj => obj.androidPackageName === id);
    });
    return uniqueArray;
  };

  //remove already installed offers and host app offer
  const removeUserAppsOffers = data => {
    return data.filter(offer => {
      const isAppInstalled =
        AndroidService.isAppInstalled(offer.androidPackageName) &&
        offer.userStatus === 'NIL';
      const isHostApp =
        offer.androidPackageName === AndroidService.getPackageName();
      return !(isAppInstalled || isHostApp);
    });
  };

  //filter host app, duplicate offers and already installed apps
  const filterOffers = data => {
    if (!data?.length) return [];
    const uniqueOffers = removeDuplicateOffers(data);
    return removeUserAppsOffers(uniqueOffers);
  };

  const onNoOffers = () => {
    //added at service layer
    // AndroidService.logEvent(OFFER_404, null);
  };

  return (
    <div className="mb-[50px]">
      <p className="text-lg font-semibold">
        {LANGUAGES_CONTENT[StorageService.getLanguage()]['offers'].all}
      </p>
      <PaginatedOffersList
        service={OfferService.getActiveOffers}
        filterFn={filterOffers}
        renderOffer={(d, i) => (
          <ActiveOfferCard
            offer={d}
            key={d.offerId}
            index={i}
            tagMap={tagMap}
          />
        )}
        onNoOffers={onNoOffers}
        isTags
        isSort
        sort={sort}
        tags={tags}
        setTags={setTags}
        setSort={setSort}
      />
    </div>
  );
}
